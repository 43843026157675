require('./bootstrap');


    $('#trucks,#small_trucks,#light_trucks,#passenger').on('keyup',function()
    {
        var trucks = $('#trucks').val();
        var small_trucks = $('#small_trucks').val();
        var light_trucks = $('#light_trucks').val();
        var passenger = $('#passenger').val();

        let _token   = $('meta[name="csrf-token"]').attr('content');

        $.ajax({
        url:'/calculate-simulator',
        type: "POST",
        data:{
        truck:trucks,
        small_truck:small_trucks,
        light_truck:light_trucks,
        passenger:passenger,
        _token: _token
    },
        success:function(response){
        if(response) {
        $('.result-simulator').text(response);
    }
    },
    })

    });

